.category-wrapper {
    display: flex;
    margin: 5px 0px 0px 10px;
    .category-obj {
        display: flex;
        // border: 1px solid;
        color: #222222;
        border-radius: 12px;
        padding: 10px 30px;
        margin: 5px;
        font-size: 17px;
        cursor: pointer;
    }

    .category-all {
        color: #000000;
        border: 1px solid #aaaaaa;
        // margin: 10px;
        // font-size: 18px;
        // font-weight: bold;
        // cursor: pointer;
    }
}

@media screen and (max-width: 800px) {
    .category-wrapper {
        
        .category-obj {
            border-radius: 0.7rem;
            padding: 0.5rem 0.9rem 0.5rem 0.9rem;
            font-size: 0.8rem;
            margin: 0.3rem;
        }
    }
}