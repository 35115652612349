/******* POPUP ******/

.popup-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(99, 99, 99, 0.8);
    // background-color: #ffffff22;
    z-index: 99;

    .popup-box {
        position: relative;
        top: 30%;
        width: 400px;
        background-color: $color-white;
        margin: 0 auto;
        text-align: center;

        .popup-star {
            font-size: 2rem;
            padding: 10px;
            text-align: center;
            color: gold;
        }
        h5 {
            font-size: 1rem;
            font-weight: bold;
        }
        .popup-selector-wrapper {
            display: flex;
            justify-content: center;
        }
        .popup-selector {
            border: 1px solid $color-gray2;
            border-radius: 10px;
            width: 130px;
            padding: 8px 0;
            margin: 0 15px;
            cursor: pointer;
            &.selected {
                background-color: $color-aqua;
                border-color: $color-aqua;
                color: $color-white;
            }
        }
        .popup-contents {
            font-size: 0.9rem;
            padding: 10px;
            text-align: center;
            line-height: 1.5;
        }
        .popup-input-unit {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px 0px;
            label {
                width: 50px;
            }
            input {
                border: 1px solid $color-gray2;
                height: 30px;
            }
        }
        .popup-action {
            display: flex;
            flex-direction: row-reverse;
            padding: 10px 0px;
            button {
                width: 100px;
                padding: 10px;
                font-size: 17px;
                background-color: $color-white;
                cursor: pointer;
                color: #b4b4b4;
                border: none;
            }
            .btn-ok {
                color: $color-aqua;
                &:disabled {
                    color: $color-gray1;
                    cursor: not-allowed;
                }
            }
            .btn-cancel {
                color: #b4b4b4;
            }
        }
        .popup-confirm {
            button {
                width: 100%;
                padding: 10px;
                font-size: 17px;
                background-color: $color-aqua;
                cursor: pointer;
                color: $color-white;
                border: none;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .popup-wrapper {

        .popup-box {
            width: 80%;
        }
    }
}

/******* POPUP ******/