/****** COMMENT *******/

.comment-area-wrapper {
    margin: 5px 0px;
    padding: 20px 23px 25px 23px;
    border-radius: 30px;
    background-color: #f4f4f4;
    // min-height: 100px;
}

.comment-list-wrapper {
    .comment-wrapper {
        display: flex;
        margin: 12px 0px;
        &.sub {
            margin-left: 2rem;
            .comment-profile-img {
                height: 35px;
                width: 35px;
            }
        }
        .profile {
            flex-shrink: 0;
        }
        .actions-drawer-target {
            align-items: flex-start;
            height: fit-content;
        }
        .comment-profile-img {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            object-fit: cover;
        }
        .com-cont-wrp {
            flex-grow: 1;
            position: relative;
            padding-left: 10px;
            .com-cont-top {
                display: flex;
                padding: 5px 0px;
                align-items: center;
                font-size: 14px;
                h5 {
                    // font-size: 15px;
                    font-weight: bold;
                }    
                .com-date {
                    margin-left: auto;
                    color: $color_gray1;
                }
            }
            .com-cont-mid {
                display: flex;
                p {
                    font-size: 14px;
                    line-height: 1.3;
                }
                .break-line {
                    margin-bottom: 3px;
                    display: block;
                }
                textarea {
                    width: 100%;
                    height: 50px;
                    resize: none;
                    padding-right: 60px;
                }
                button {
                    position: absolute;
                    right: 0px;
                    height: 50px;
                    font-size: 16px;
                    color: $color_white;
                    background-color: $color_gray2;
                    border: none;
                    border-radius: 10px;
                    z-index: 1;
                }
            }
            .com-cont-bot {
                display: flex;
                font-size: 14px;
                color: $color_gray2;
                .cmt-like-wrp {
                    margin-right: 0.5rem;
                    cursor: pointer;
                    display: flex;
                    &.liked {
                        color: $color_pink;
                    }
                }
            }
        }
        .actions-wrapper {
            display: flex;
            margin-left: 0.5rem;
            margin-right: 0px;
            justify-content: space-between;
            // font-size: 1rem;
            // padding-top: 5px;
            .action-icons {
                color: $color_gray1;
                font-size: 1rem;
                margin: 0px 3px;
            }
    
            .edit-wrapper {
                display: inline-block;
            }
            .delete-wrapper {
                display: inline-block;
            }
        }
    }
}

.comment-write {
    height: 3rem;
    position: relative;
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: $color-white;
    border-radius: 15px;
    &:focus-within {
        height: 5rem;
    }
    &.sub {
        margin-left: 2rem;
    }
    button {
        position: absolute;
        right: 0;
        height: 100%;
        width: 20%;
        font-size: 1rem;
        color: $color_white;
        border: none;
        border-radius: 15px;
        background: #fad55f;
        &:active {
            background: #6d869c;
        }
    }
}

@media screen and (max-width: 800px) {
    .comment-area-wrapper {
        margin: 5px 7px;
        padding: 1.1rem;
    }
}
