
.documenu-wrapper {
    display: flex;

    .doc-gen-list-handler {
        padding: 10px;
        cursor: pointer;
    }
    .doc-gen-list-wrapper {
        width: 100%;
        overflow: hidden;
        .doc-gen-list {
            display: flex;
            transition: all 1s;
            li {
                padding: 10px;
                cursor: pointer;
                transition: all 1s;
                border: 1px dashed white;
                border-radius: 40%;
    
                &:hover {
                    border: 1px dashed rgb(55, 69, 194);
                }
            }
        }
    
    }
}

.crt-docu-wrapper {
    .input-label {
        font-size: 15px;
        font-weight: bold;
        margin: 0px 10px;
        flex-shrink: 0;
        width: 60px;
        text-align: center;
    }
    .docu-option-generation {
        margin-right: 20px;
        margin: 10px 0px;
        display: flex;
        align-items: center;
    }
    .docu-category-list-wrapper {
        display: flex;
        margin: 15px 0px;
        align-items: center;
        .docu-category-unit-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
        .category-unit {
            flex-shrink: 0;
            // margin: 10px 0px;
            padding: 8px 0px;
            input {
                display: none;
            }
            label {
                font-weight: normal;
                cursor: pointer;
                margin: 0px 5px;
                padding: 5px 10px;
                border: 1px solid $color_gray2;
                border-radius: 8px;
            }
            input:checked + label {
                // font-weight: bold;
                color: #eeeeee;
                background-color: $color_gray2;
            }
        }
    }
    
    .docu-title-wrapper {
        margin: 10px 0px;
        height: 40px;
        display: flex;
        align-items: center;
        .docu-title {
            flex-grow: 1;
            height: 30px;
        }
    }
    .docu-desc-wrapper {
        margin: 10px 0px;
        // height: 200px;
        display: flex;
        align-items: center;
        .docu-desc {
            flex-grow: 1;
            height: 200px;
        }
    }
    .docu-files-wrapper {
        margin: 10px 0px;
        // height: 200px;
        display: flex;
        align-items: center;
        // .docu-desc {
        //     flex-grow: 1;
        //     height: 200px;
        // }
        .docu-input-file {
            display: none;
        }
        .file-list {
            align-items: center;
            // height: 25px;
            p {
                font-size: 15px;
            }
            // .far {
                // font-size: 15px;
            // }
        }
    }
}


.doculist-wrapper {
    font-size: 15px;
    .doculist-head {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
        color: $color-white;
        background-color: #bcbdbc;
        padding: 15px 15px;
    }
    .doculist-body {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
        align-items: center;
        padding: 10px 15px;
        border-bottom: 2px solid #ececec
    }
    .docu-generation {
        flex-basis: 40px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        // align-items: center;
    }
    .docu-category {
        flex-basis: 55px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
    }
    .docu-memo {
        flex-grow: 1;
        padding-left: 11px;
        a {
            color: #070707;
        }
    }
    .docu-download {
        flex-basis: 80px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        a {
            color: $color_gray2;
            transition: all 0.2s;
            &:hover {
                color: $color_gray1;
            }
        }
    }
    .docu-comments {
        flex-basis: 60px;
    }
    table {
        width: 100%;
    }
    thead {
        color: $color-white;
        background-color: #bcbdbc;
    }
    th {
        padding: 15px;
    }
}


.doc-wrapper {
    padding-top: 30px;
    width: 90%;
    margin: 0 auto;
    .doc-title {
        font-size: 20px;
        padding-top: 10px;
        padding-bottom: 4px;
        background-color: #008f94;
        color: $color-white;
        display: flex;
        a {
            float: left;
            margin-left: 5px;
            color: $color-white;
        }
        h5 {
            flex-grow: 1;
            font-weight: bold;
            text-align: center;
        }
    }
    .doc-desc {
        padding-top: 1rem;
        padding: 1rem;
        border: 1px solid #C9C9C9;
        min-height: 300px;
        line-height: 200%;
        border-top: none;
    }
}

.custom-hwp {
    background-image: url('../../assets/img/board/file_hwp.svg');
    // background-size:
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 1rem;
    height: 1.5rem;
    display: block;
    position: relative;
}