/****** PHOTO *******/
.photo-list-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
    .photo-wrapper {
        width: calc(25% - 2px);
        margin: 1px;
        position: relative;
        &::before {
            content: '';
            padding-top: 100%;
            float: left;
        }
        &::after {
            content: '';
            display: block;
            clear: both;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover {
            .photo-cover {
                max-height: 100px;
                transition: all 0.15s ease-in-out;
            }
        }
        .photo-cover {
            position: absolute;
            display: flex;
            justify-content: space-between;
            color: #C9C9C9;
            bottom: 0;
            z-index: 1;
            width: 100%;
            // padding: 5px;
            font-size: 16px;
            background-color: rgba(22, 22, 22, 0.5);
            max-height: 0;
            overflow: hidden;
            .photo-cover-unit {
                padding: 0.5rem;
                display: flex;
            }
        }
    }
    
    .photo-wrapper:hover {
        background-color: rgba(100, 100, 100, 0.8);
    }
}

.photo-popup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo-section-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    height: 85%;
    background-color: $color-white;
    .photo-section-bottom {
        display: flex;
        flex-grow: 1;
        max-height: calc(100% - 2.75rem);
    }
    .photo-section-left {
        flex-basis: 66%;
        max-height: 100%;
        @media screen and (max-height: 400px) {
            display: none;
        }
    }
    .photo-section-right {
        flex-grow: 1;
        display: inline-flex;
        flex-direction: column;
        flex-basis: 400px;
        background-color: $color-white;
        overflow: auto;
        // max-height: 100%;
        // overflow: hidden;
        .comment-area-wrapper {
            height: auto;
            // overflow-y: auto;
        }
    }
    .photo-alb-title-wrp {
        // height: 50px;
        background-color: $color_blue1;
        color: #F1F1F1;
        // display: flex;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 2rem;
        // padding-top: 10px;
        // padding-bottom: 8px;
        position: relative;
        .photo-alb-title-back {
            position: absolute;
            left: 0;
            margin-top: -3px;
            margin-left: 5px;
            color: $color-white;
        }
        .photo-alb-title {
            display: flex;
            padding: 0.5rem 0.5rem;
            align-items: center;
            font-size: 20px;
            flex-grow: 0;
            font-weight: bold;
            color: $color-white;
            h5 {
                display: inline;
            }
        }
        .actions-drawer {
            position: absolute;
            padding-right: 7px;
            right: 0;
            // height: calc(100% - 10px);
        }
    }
    .photo-img-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        background-color: #1d1d1d;
        height: 100%;
        // max-height: 500px;
        img {
            object-fit: contain;
        }
        .photo-move-action {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            max-width: 0px;
            overflow: hidden;
            height: 100%;
            z-index: 1;
            transition: all 0.2s;
            // cursor: pointer;
            user-select: none;
            &.prev {
                left: 0;
                // background: linear-gradient(-90deg, rgba(128, 128, 128, 0.01), rgba(128, 128, 128, 0.3));
            }
            &.next {
                right: 0;
                // background: linear-gradient(90deg, rgba(232, 232, 232, 0.01),rgba(232, 232, 232, 0.3));
            }
            .photo-move-btn {
                background-color: rgba(200, 200, 200, 0.8);
                border-radius: 50%;
                border: none;
                height: 2.5rem;
                width: 2.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .ri-icons {
                font-size: 1.5rem;
                color: $color-gray5;
            }
        }
        .photo-action-fullscreen-wrapper {
            position: absolute;
            right: 0px;
            bottom: 0px;
            z-index: 2;
            background-color: rgba(200, 200, 200, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            padding: 0.3rem;
        }
        &:hover {
            .photo-move-action {
                max-width: 200px;
            }
        }
    }

    .photo-contents-wrapper {
        margin-top: 10px;
        padding: 0px 1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        .actions-drawer {
            position: absolute;
            top: 5px;
            right: 10px;
        }
        .info-wrapper {
            flex-basis: 50%;

            .info-tags {
                display: flex;
                flex-wrap: wrap;
                padding: 0px 23px 3px 23px;
                .tag-unit {
                    color: #eeeeee;
                    margin: 3px;
                    padding: 5px 9px;
                    border-radius: 10px;
                    font-size: 13px;
                    user-select: none;
                }
                .tag-type-1 {
                    border: 1px solid #e25d7f;
                    background-color: #e25d7f;
                }
                .tag-type-2 {
                    border: 1px solid #3a68a0;
                    background-color: #3a68a0;
                }
            }
            .info-title-date {
                display: flex;
                margin: 10px 0px;
            }
            .info-basic {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: $color-gray2;
                justify-content: flex-end;
                margin: 0px 1rem;
                .ri-icons  {
                    font-size: 1rem;
                    margin-left: 7px;
                    margin-right: 2px;
                }
                p {
                    font-size: 13px;
                }
            }
            .info-date {
                // padding: 7px 0px 0px 5px;
                width: 50%;
                padding-right: 1rem;
                text-align: right;
                font-size: 13px;
                color: #3E3E3E;
            }

            .info-text-infos-wrapper {
                display: flex;
                flex-direction: column;
                .info-text-wrapper {
                    position: relative;
                    padding: 1rem 0px;
                    max-height: 200px;
                    overflow: auto;
                }
                .info-infos-wrapper {
                    margin: 0px;
                    font-size: 15px;    
                }
            }
            .photo-info-unit {
                display: flex;
                align-items: center;
                margin: 3px 0px;
                .photo-info-label {
                    margin: 0px 3px;
                    padding: 5px;
                    width: 50%;
                    font-weight: bold;
                    color: $color-gray1;
                    flex-shrink: 0;
                }
                .photo-info-profile {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;            
                }
                .photo-info-fl {
                    margin: 0px 1px;
                    padding: 5px;
                    font-weight: bold;
                    color: $color-black;
                    // background-color: $color-gray3;
                    // border-radius: 5px;
                    // color: $color-white;
                }
            }

            .editable {
                // background-color: $color-gray5;
                border: 1px solid black;
            }

            h4 {
                // margin-left: 0.5rem;
                width: 50%;
                font-family: 'S-CoreDream-5Medium';
                font-size: 23px;
                
            }
            p {
                font-size: 15px;
                line-height: 20px;
            }
            table {
                margin: 10px 10px 10px 23px;
                font-size: 15px;
                tr {
                    margin: 10px 0px 10px 0px;
                }
                td {
                    padding: 5px;
                }
            }
        }
        .user-wrapper {
            flex-basis: 50%;
            img {
                width: 70px;
                height: 70px;
                border-radius: 50%;
            }
            .username {
                display: inline-block;
            }
        }
    }
}

.crt-photo-popup {
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    
    .crt-photo-wrp {
        position: relative;
        width: 1400px;
        margin: 0 auto;
        margin-top: 5%;
        background-color: $color-white;
        z-index: 99;
        .crt-photo-header {
            padding: 20px 0 20px 0;
            background-color: $color-blue3;
            h3 {
                text-align: center;
                color: $color-white;
                font-size: 24px;
            }
        }
        .crt-photo-body {
            height: 600px;
            display: flex;
            .crt-photo-left {
                width: 450px;
                padding: 10px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-content: flex-start;
                overflow: auto;
                .block-constant {
                    width: 33%;
                    position: relative;
                    &::before {
                        content: '';
                        padding-top: 100%;
                        float: left;
                    }
                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                }
                .remove-icon-wrapper {
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    z-index: 1;
                    &:hover {
                        i {
                            color: #ff3e3e;
                            transition: all 0.1s;
                        }
                    }
                }

                label {
                    // height: 135px;
                    width: 100%;
                    height: 100%;
                    flex: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    &::before {
                        content: '';
                        padding-top: 100%;
                        float: left;
                    }
                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                    .add-photo {
                        border: 1px solid #C9C9C9;
                        border-radius: 50%;
                        width: 70%;
                        height: 70%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                input {
                    display: none;
                }
                .photo-thumbnail {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    cursor: pointer;
                }
                .selected {
                    border: 3px solid #49A0AE;
                }
                .default {
                    opacity: 0.8;
                }
            }
            .crt-photo-center {
                background-color: #1d1d1d;
                width: 500px;
                display: flex;
                justify-content: center;
                align-content: center;
                // text-align: center;
                img {
                    max-width: 100%;
                    object-fit: contain;
                    // max-height: 100%;
                }
            }
            .crt-photo-right {
                display: flex;
                position: relative;
                flex-direction: column;
                background-color: $color-white;
                width: 450px;
                .crt-photo-right-top {
                    // height: 80%;
                    flex-grow: 1;
                    overflow: auto;
                }
                .message-info {
                    padding: 15px;
                    font-size: 17px;
                }
                
            }
        }
    }
    .edt-photo-wrp {
        width: 950px;
    }
}

.crt-photo-btn-wrapper {
    margin-top: auto;
    width: 100%;
    display: flex;
    button {
        height: 50px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        transition: all 0.2s;
    }
    .btn-cancel {
        flex-basis: 33%;
        color: #1d1d1d;
        background-color: #F1F1F1;
        &:hover {
            background-color: darken($color: #F1F1F1, $amount: 10%);                            
        }
    }
    .btn-ok {
        flex-basis: 67%;
        flex-grow: 1;
        color: #F1F1F1;
        background-color: $color-blue3;
        &:hover {
            background-color: darken($color: $color-blue3, $amount: 10%);                            
        }
        &:disabled {
            background-color: #646464;
        }
    }
}


.photo-input-area-wrapper {
    padding: 10px;
    overflow: auto;
    .input-title {
        width: 100%;
        height: 40px;
        border-top: 1px solid #C9C9C9;
        border-bottom: 1px solid #C9C9C9;
        border-left: none;
        border-right: none;
    }
    .input-desc {
        width: 100%;
        height: 100px;
        border: none;
        border-bottom: 1px solid #C9C9C9;
        resize: none;
    }
    .photo-infos {
        width: 100%;
        position: relative;
        padding: 20px 10px;
        background-color: #F1F1F1;
        .photo-info {
            margin: 10px 0px;
            display: flex;
            align-items: center;
            position: relative;
            .label-wrapper {
                // display: inline-block;
                flex-basis: 6rem;
                // padding-left: 20px;
                // text-align: center;
            }
            .input-wrapper {
                display: inline-block;
                width: 50%;
            }
            input {
                height: 1.3rem;
                border: 1px solid #C9C9C9;
            }
        }
    }    
}

.photo-list-loader-wrapper {
    display: flex;
    justify-content: center;
    margin: 1rem;
}


@media screen and (max-width: 1500px) {
    .crt-photo-popup {
        .crt-photo-wrp {
            width: 1000px;
            .crt-photo-body {
                height: 500px;
            }
        }
    }
}


@media screen and (max-width: 800px) {
    .photo-list-wrapper {
        padding: 0rem 0.5rem;
        .photo-wrapper {
            width: calc(50% - 2px);
        }
    }

    .photo-section-wrapper {
        width: 90%;
        height: 90%;
        .photo-section-bottom {
            display: flex;
            overflow: auto;            
            flex-direction: column;
        }

        .photo-img-wrapper {
            max-height: 300px;
        }

        .photo-contents-wrapper {
            padding: 0.5rem 1rem;
            display: block;
            .actions-drawer {
                position: absolute;
                top: 14px;
                right: 3px;
            }    
            .info-wrapper {
                h4 {
                    margin-left: 0px;
                    font-size: 1.4rem;
                }
                .info-date {
                    padding-right: 5px;
                }
                .info-tags {
                    padding: 0px 0px 0.5rem 0px;
                }
                .info-basic {
                    flex-direction: column;
                    align-items: flex-end;
                }
                .info-text-infos-wrapper {
                    flex-direction: column;
                    padding: 0px;
                    .info-text-wrapper {
                        border: none;
                        width: 100%;
                    }
                    .info-infos-wrapper {
                        width: 100%;
                    }
                    table {
                        margin: 0px;
                    }

                }
            }

            .user-wrapper {
                padding: 0.5rem;
                display: flex;
                img {
                    width: 3rem;
                    height: 3rem;
                }
                .username {
                    padding: 1rem 1rem 1rem 1rem;
                    font-weight: bold;
                }
            }
        }
        // .actions-wrapper {
        //     margin: 0rem 0rem;
        // }    
    }


    .crt-photo-popup {
        
        .crt-photo-wrp {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            background-color: $color-white;
            z-index: 99;
            .crt-photo-header {
                height: 4rem;
                padding: 20px 0 20px 0;
                background-color: rgb(0, 33, 80);
                h3 {
                    text-align: center;
                    color: $color-white;
                    font-size: 24px;
                }
            }
            .crt-photo-body {
                height: calc(100% - 4rem);
                flex-direction: column;
                overflow: auto;
                flex-wrap: nowrap;
                .crt-photo-left {
                    width: 100%;
                    height: 8rem;
                    overflow: auto;
                    flex-shrink: 0;
                    flex-wrap: nowrap;
                    .block-constant {
                        width: 6rem;
                        height: 6rem;
                        flex: none;
                    }
                    label {
                        user-select: none;
                        .add-photo {
                            width: 3.5rem;
                            height: 3.5rem;
                        }
                    }                    
                }
                .crt-photo-center {
                    display: none;
                }
                .crt-photo-right {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .input-title {
                        height: 4rem;
                    }
                    .input-desc {
                        height: 8rem;
                    }
                }
            }
        }
    }
    .crt-photo-btn-wrapper {
        position: initial;
    }
}


/****** PHOTO *******/