.post-wrapper {
  // padding-top: 30px;
  width: 100%;
  margin: 0 auto;
  // border-top: 1px solid #c9c9c9;
  .post-title {
    position: relative;
    font-size: 20px;
    padding-top: 11px;
    padding-bottom: 9px;
    background-color: $color_aqua;
    color: #f1f1f1;
    display: flex;
    .post-title-back {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      left: 0;
      top: 0;
      // margin-top: -3px;
      margin-left: 5px;
      color: $color-white;
    }
    h5 {
      flex-grow: 1;
      font-weight: bold;
      text-align: center;
      word-break: break-all;
      padding: 0px 30px;
    }
    .actions-drawer {
      position: absolute;
      display: flex;
      align-items: center;
      padding-right: 7px;
      height: 100%;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
  .post-info-other {
    font-size: 15px;
    border: 2px solid #e8e8e8;
    border-top: none;
    display: flex;
    justify-content: space-between;
    padding: 10px 23px;
    .post-author {
      display: flex;
      align-items: center;
    }
  }
  .post-date-created {
    position: relative;
    .post-date-updated {
      display: none;
    }
    &:hover {
      .post-date-updated {
        position: absolute;
        left: 0px;
        top: 100%;
        display: block;
        width: 150px;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: $color_gray5;
      }
    }
  }
  .post-content {
    border: 2px solid #e8e8e8;
    min-height: 200px;
    border-top: none;
  }
  .post-filelist-wrapper {
    margin: 10px 0px;
    ul {
      li {
        font-weight: bold;
        padding: 5px 0px;
        a {
          color: #646464;
        }
      }
    }
  }
  .enif-divider {
    margin: 0px 0px 10px 0px;
  }
}

.doc-text-wrp {
  font-size: 0.9rem;
  min-height: 300px;
  line-height: 150%;
  padding: 1rem;
}

.file-download-wrapper {
  padding: 10px 23px;
  background-color: #e8e8e8;
  .file-download-list {
    a {
      display: inline-flex;
      align-items: center;
      color: $color_gray1;
    }
    .file-download-name {
      font-weight: bold;
    }
  }
  .file-attached-list {
    display: inline-flex;
    align-items: center;
    color: $color_gray1;
    &.deleted {
      text-decoration: line-through;
    }
  }
}

.attach-file-wrapper {
  display: flex;
  margin: 20px 0px;
  height: 100%;
  width: 100%;
  .file-list {
    flex-grow: 1;
    padding: 10px;
    word-break: break-all;
    border: 1px solid #ccc;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    li {
      margin: 3px 0px;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
    .ri-icons {
      margin: 0px 0.5rem;
      color: $color_gray3;
      font-size: 1.2rem;
      &:hover {
        color: rgb(255, 79, 79);
      }
    }
  }
  .guide-message {
    color: rgb(255, 79, 79);
  }

  .attach-btn-wrapper {
    background-color: $color-gray2;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: 50px;
    height: 100%;
    width: 100px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $color-white;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: #646464;
    }
  }
  input {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .post-wrapper {
    width: 100%;
    .post-info-other {
      padding: 0.8rem 1.5rem;
    }
    .post-title {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
  }
}
