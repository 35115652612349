.photoboard-wrapper {

    .view-type-selector-wrapper {
        display: flex;
        width: 100%;
        font-size: 20px;
        // margin-bottom: 12px;
        .view-type-selector {
            width: 50%;
            text-align: center;
            padding: 10px 0px 10px 0px;
            // border: 2px solid #686868;
            background-color: $color-gray5;
            color: $color-gray2;
            cursor: pointer;
            transition: all 0.5s;
            &:hover {
                color: $color-gray1;
            }
        }
        .selected {
            background-color: $color-aqua;
            color: $color-white;
            &:hover {
                color: $color-white;
            }
        }
    }
}

.memory-title {
    display: inline-block;
    background-image: url("../../assets/img/board/title_memory.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 270px;
    height: 60px;
    padding: 19px 0px 20px 100px;
    margin: 10px 0px 15px 0px;
}
.astrophoto-title {
    display: inline-block;
    background-image: url("../../assets/img/board/title_astrophoto_mini.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 250px;
    height: 60px;
    padding: 19px 0px 20px 107px;
    margin: 10px 0px 15px 5px;
}

.btn-crt-album {
    position: absolute;
    right: -70px;
    top: 100px;
}

@media screen and (max-width: 800px) {
    .btn-crt-album {
        position: sticky;
        margin-left: 75%;
        // top: -5rem;
        // right: 2rem;
        // position: fixed;
        // top: initial;
        // right: 70px;
        // bottom: 2rem;
    }
}