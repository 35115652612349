/***** LOG IN *****/

.login-wrapper {
    // position: fixed;
    position: absolute;
    display: block;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    background-color: #182641;
    background-image: url("../../assets/img/login_background.jpg");

    .logo-wrapper {
        position: relative;
        // height: 250px;
        width: 100%;
        // padding-top: 1rem;
        text-align: center;
        img {
            margin: 0 auto;
            height: 15rem;
            width: 15rem;
        }
    }

    .inputs-wrapper {
        margin: 0 auto;
        width: 400px;
        text-align: center;
        .auto-checker {
            // text-align: left;
            display: flex;
            .toggle-switch {
                position: relative;
                display: inline-block;
                width: 40px;
                height: 20px;
                background-color: rgba(255, 255, 255, 0.25);
                border-radius: 20px;
                transition: all 0.3s;
                cursor: pointer;
            }
            .toggle-switch::after {
                content: '';
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 18px;
                background-color: white;
                top: 1px;
                left: 1px;
                transition: all 0.3s;
            }
            input[type='checkbox']:checked + .toggle-switch::after {
                transform: translateX(20px);
            }
            input[type='checkbox']:checked + .toggle-switch {
                background-color: #57adb6;
            }
            input[type="checkbox"] {
                display: none;
            }
            .auto-checker-text {
                padding: 3px 0px 0px 7px;
                color: white;
                font-size: 16px;
            }
        }
        .menu-txt-wrapper {
            color: white;
            font-size: 0.85rem;
            margin-top: 1.5rem;
            a {
                color: white;
            }

            .menu-txt-signup {
                float: left;
                cursor: pointer;
                &:hover {
                    font-weight: bold;
                }
            }
            .menu-txt-find {
                float: right;
                cursor: pointer;
                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
    
    .btn-guest{
        height: 2.5rem;
        width: 100%;
        color: white;
        font-size: 1rem;
        letter-spacing: 0.1rem;
        border: none;
        border-radius: 0.5rem;
        background-color: #596376;
        // background: transparent;
        // border: 1px solid #ffffff;
        transition: all 1s;
        &:hover {
            background-color: #596376a0;
        }
    }
    
    footer {
        position: absolute;
        bottom: 0;
        background-color: transparent;
        color: white;
        width: 100%;
    }

    .footer-contents {
        width: 100%;
        text-align: center;
        text-shadow: 0 0 10px;
        p {
            margin: 10px;
            line-height: 1.5;
        }
    }
}

/****** for mobile ~ 800px ******/
@media screen and (max-width: 800px) {

    .login-wrapper {
        background-image: url("../../assets/img/login_background_m.jpg");
        .logo-wrapper {
            margin-bottom: -1rem;
            img {
                height: 16rem;
                width: 16rem;
            }
        }
        .inputs-wrapper {
            width: 75%;
        }

        .footer-contents {
            p {
                font-size: 0.8rem;
            }
        }
    }
}
/***** LOG IN *****/