/***** header *****/

.main-header {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
}

@media screen and (max-width: 800px) {
    .main-header {
        width: 100%;
        height: auto;
        margin: 0 auto;
        background-color: #05070e;
        background-image: none;
    }
}

.main-menu-nav-wrapper {
    height: 42px;
    text-align: center;
    // padding-top: 12px;
    background-color: #05070e;
    box-shadow: 0px 2px 2px 0px rgba(50, 45, 119, 0.61);
    width: 100%;
    z-index: 10;
    position: sticky;
    top: 0;

    nav {
        color: aliceblue;
        height: 42px;
        background-color: $color-blue1;
        .nav-items {
            height: 100%;
        }
    }

    .menu-nav {
        height: inherit;
        display: inline-block;        
        color: $color-white;
        background-color: $color-blue1;
        &:hover {
            .menu-item-1 {
                // border-bottom: 3px solid #fad55f;
                &::after, &::before {
                    width: 50%;
                }
            }
            &:hover {
                .menu-nav-sub {
                    max-height: 400px;
                }
            }
        }
        .menu-item-1 {
            display: block;
            position: relative;
            height: 35px;
            padding-top: 13px;
            padding-left: 10px;
            padding-right: 10px;
            margin: 0px 10px 0px 10px;
            // line-height: 25px;
            color: $color-white;
            cursor: pointer;
            &::before, &::after {
                border: 0 solid transparent;
                transition: all 0.3s ease-in-out;
                content: '';
                height: 24px;
                position: absolute;
                width: 0px;
                border-bottom: 3px solid #fad55f;
                bottom: -2px;
            }
            &::before {
                right: 50%;
            }
            &::after {
                left: 50%;
            }
        }
        .menu-nav-sub {
            position: absolute;
            top: 100%;
            background-color: #fad55f;
            z-index: 10;
            color: $color-white;
            transition: all 0.3s ease-in-out;
            overflow: hidden;
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
            max-height: 0;
            // &:hover {
            //     max-height: fit-content;
            // }
            a {
                color: $color-white;
            }
            > ul {
                width: 200px;
                z-index: 10;
                li {
                    color: $color-white;
                    width: 100%;
                    padding: 10px;
                    text-align: left;
                    text-shadow: 0px 0px 3px #e6ad0f;
                    transition: background-color 0.2s;
                    z-index: 10;
                    font-weight: bold;
                    &:hover {
                        color: #646464;
                        background-color: #fce8a7;
                        text-shadow: none;
                    }
                    .ri-external-link-line {
                        float: right;
                    }
                }
            }
        }
        // .menu-nav-hidden {
        //     max-height: 0;
        // }
    }    
}

@keyframes show {
    from {
        height: 0px;
    }
    to {
        height: auto;
    }
}

.nav-toggle {
    display: none;
}

.navicon {
    display: none;
}

/* navigation icon for Mobile Layout */
.navicon {
    cursor: pointer;
    height: 60px;
    padding: 28px 15px;
    position: absolute;
    top: 0; right: 0;

    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */
}

/* nav icon의 내부 막대 */
.navicon-bar {
    background-color: rgb(255, 255, 255);
    display: block;
    position: relative;
    /* navigation icon animation */
    transition: background-color .2s ease-out;
    width: 20px;
    height: 2px;
}

.navicon-bar::before,
.navicon-bar::after {
    background-color: rgb(255, 255, 255);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    /* navigation icon animation */
    transition: all .2s ease-out;
    width: 100%;
}
.navicon-bar::before {
    top: -7px;
}
.navicon-bar::after {
    top: 7px;
}
  /* toggle navigation icon */
.nav-toggle:checked ~ .navicon > .navicon-bar {
    background: transparent;
}
.nav-toggle:checked ~ .navicon > .navicon-bar::before {
    transform: rotate(45deg);
    top: 0;
}
.nav-toggle:checked ~ .navicon > .navicon-bar::after {
    transform: rotate(-45deg);
    top: 0;
}



/***** header *****/

@media screen and (max-width: 800px) {

    .main-menu-nav-wrapper {
        width: 100%;
        height: 2rem;
        font-size: 0.8rem;
        box-shadow: 0px 1px 1px 0px rgba(105, 105, 105, 0.61);

        &:hover {
            margin-bottom: 2rem;
        }

        nav {
            height: 2rem;
            margin: 0;
            .nav-items {
                display: flex;
                background-color: #7092c4;
                // box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.089);
                width: 100%;
                position: absolute;
                justify-content: space-around;
            }
        }
        
        .menu-nav {
            background-color: #7092c4;
            flex-shrink: 0;
            margin: 0;
            span {
                display: none;
            }
            .menu-item-1 {
                padding-top: 0;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                margin: 0px 0px 0px 0px;
                line-height: 2rem;

                &:hover {
                    text-decoration: none;
                    color: #fad55f;
                    border-bottom: none;    
                }
            }
            .menu-nav-sub {
                // position: relative;
                // top: 0;
                left: 0;
                width: 100%;
                height: 2rem;
                background-color: #fdeed5;
                a {
                    color: #363941;
                }
                > ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    overflow-x: auto;
                    a {
                        display: inline-block;
                        flex-shrink: 0;
                    }
                    li {
                        height: 100%;
                        color: #05070e;
                        padding: 0.7rem 0.5rem;
                        text-shadow: none;
                        font-weight: normal;
                        &:hover {
                            background-color: #ffdea6;
                        }
                    }
                }
            }
        }
    }
    
    .nav-open {
        margin-bottom: 2rem;
    }


    .navicon {
        height: 15px;
        display: block;
    }

    /* View navigation item */
    .nav-toggle:checked ~ .nav-items {
        // display: block;
        width: 100%;
        background-color: $color-white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
    }    
}

@media screen and (max-width: 350px) {

    .main-menu-nav-wrapper {
        font-size: 0.77rem;
                
        .menu-nav {
            .menu-item-1 {
                padding-left: 0.2rem;
                padding-right: 0.2rem;
            }
        }
    }


}