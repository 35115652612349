.mgt-user-wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h4 {
    margin: 1rem;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
  }
}

.mgt-user-table-wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.mgt-user-table {
  padding: 1rem;
  font-size: 0.9rem;
  thead {
    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: center;
  }
  td,
  th {
    padding: 0.5rem;
    border: 1px solid $color-gray5;
    white-space: nowrap;
    &.selected {
      background-color: rgb(240, 240, 255);      
    }
  }
  th {
    font-weight: bold;
    background-color: rgb(220, 220, 220);
  }
}

@media screen and (max-width: 800px) {

  .mgt-user-table-wrp  {
      justify-content: flex-start;
      overflow: auto;
  }
}

