
/***** SIGN UP *****/

.sign-up-wrapper {
    position: absolute;
    display: block;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    padding-left: 8%;
    padding-right: 8%;
    background: linear-gradient(0deg, #fef5ce,#c7dbc5 50%, #4ca1af);

    h2 {
        text-align: center;
        color: $color-white;
        padding: 1.5rem;
        font-size: 2rem;
    }
    .div-agreement {
        height: 600px;
        width: 800px;
        margin: 5px auto;
        padding: 3px;
        overflow: auto;
        border: 1px solid #9eb4b4;
        font-size: 0.85em;
        background-color: #ffffff44;
        h3 {
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 1.5rem;
        }
        p {
            line-height: 1.5;
        }
    }
    input[type="checkbox"] {
        background-color: $color-white;
        border: none;
    }

    .input-text {
        margin-top: 5px;
        height: 35px;
    }
    .signup-input-wrapper {
        width: 600px;
        margin: 0 auto;
        padding: 20px;
        // border: 2px #d6d6d6 solid;
        // border-radius: 10px;
        .enif-input-field {
            margin: 0 auto;
            margin-top: 15px;
            width: 400px;
            // height: 50px;
            background-color: $color-white;
            border-radius: 20px;
            padding: 10px;
            label {
                display: inline-block;
                width: 40%;
                font-size: 0.9rem;
                font-weight: bold;
                margin: 3px;
                color: #666666;
            }
            input {
                display: inline-block;
                height: 35px;
                width: 55%;
                border: none;
            }
            p {
                text-align: right;
                font-size: 0.8rem;
                color: red;
            }
            textarea {
                margin-top: 5px;
                display: block;
                width: 100%;
                height: 100px;
                resize: none;
                overflow: auto;
                border: none;
            }
        }
        .profile-signup {
            background: transparent;
            border: 1px solid $color-white;
            label {
                width: 100%;
                span {
                    display: inline-block;
                    width: 35%;
                }
                .btn-profile {
                    text-align: right;
                    display: inline-block;
                    background-color: $color-white;
                    padding: 0.5rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    border-radius: 10px;
                }
            }
            input {
                display: none;
            }
        }
    }
    .signup-btn {
        display: block;
        width: 400px;
        align-items: center;
        background-color: #57adb6;
        border-radius: 1rem;
        height: 4rem;
        font-size: 1.5rem;
        cursor: pointer;
        &:disabled {
            background-color: #666666;
            cursor: not-allowed;
        }
    }
}


/****** for mobile ~ 800px ******/
@media screen and (max-width: 800px) {
    .sign-up-wrapper {
        // position: fixed;
        // z-index: 1;
        // margin: 0;
        // top: 0;
        // left: 0;
        // width: 100%;
        // overflow-y: scroll;
        // height: 100%;
        // background-color: #182641;
        .div-agreement {
            height: 25rem;
            width: 100%;
        }
    
        .signup-input-wrapper {
            width: 100%;
            padding: 1rem;
            .enif-input-field {
                width: 100%;
                border-radius: 10px;
                padding: 0.3rem;
                label {
                    font-size: 0.8rem;
                }
            }
        }
        .signup-btn {
            width: 100%;
            height: 3rem;
            font-size: 1rem;
        }
    }
}

/***** SIGN UP *****/