.profile-mini-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0 auto;
  position: relative;
  width: 80%;
  padding-top: 0px;
  padding-bottom: 0px;
 
  .profile-img-wrapper {
    // width: 20%;
    width: 58px;
    height: 58px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .nickname {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    // border-right: 2px solid #F4F4F4;
  }
  .userdesc {
    width: 50%;
    // flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    align-content: flex-start;
    text-align: left;
    height: 70px;
    padding-top: 10px;
    padding-left: 20px;
    min-height: 70px;
    line-height: 16px;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 25px;
      margin: 0 auto;
      background: linear-gradient(rgba(255, 255, 255, 0.01), #ffffff);
    }
    &.expanded::after {
      display: none;
    }
  }
  .expanded {
    height: auto;
  }
  .icon-expand {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.profile-img-wrapper.with-border {
  .profile-img-border {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    /* Sun */
    &.grade1 {
      background: linear-gradient(
        125deg,
        #e86200 0%,
        #ffb000 20%,
        #ffec9f 30%,
        #ffa100 50%,
        #ffec9f 70%,
        #ffb000 80%,
        #e86200 100%
      );
    }
    /* Earth */
    &.grade3 {
      background: linear-gradient(125deg, #fff900 0%, #fff900 10%, #29d5d6 40%, #2fa9ea 70%, #84ff00 100%);
    }
    /* Mars */
    &.grade4 {
      background: linear-gradient(180deg, #f3f1e6 0%, #de7702 25%);
    }
    /* Jupiter */
    &.grade5 {
      background: linear-gradient(180deg, #ecddb3 10%, #efbf71 25%, #ecddb3 50%, #efbf71 75%, #ecddb3 90%);
    }
    /* Saturn */
    &.grade6 {
      background: linear-gradient(180deg, #ecddb3 25%, #4a4a4a 50%, #ecddb3 75%);
    }
    /* Uranus */
    &.grade7 {
      background: #ade8e1;
    }
    /* Neptune */
    &.grade8 {
      background: #74a9ff;
    }
    /* Pluto */
    &.grade9 {
      background: #999;
    }
  }
  .profile-img {
    height: 90%;
    width: 90%;
    border-radius: 50%;
    object-fit: cover;
    box-sizing: border-box;
    border: 3px solid #fff;
    // cursor: pointer;
  }
}


.profile-popup-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  // position: absolute;
  // bottom: 0;
  // left: 0;
  cursor: default;
  box-shadow: 1px 1px 1px 2px $color-gray4;
  h5 {
    margin: 2rem;
    font-size: 1.2rem;
    color: $color-aqua;
    text-align: center;
  }
  .profile-popup {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    background-color: $color-white;  
  }
  .profile-img-wrapper {
    width: 58px;
    height: 58px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .profile-nickname {
    margin: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: $color-black;
    text-align: center;
  }
  .profile-units-wrapper {
    margin: 1rem 0 2rem 0;
    width: 100%;
  }
  .profile-unit {
    margin: 0.5rem 2rem;
    display: flex;
    text-align: left;
    .profile-unit-label {
      width: 30%;
      color: $color-gray2;
    }
    .profile-unit-info {
      width: 70%;
      word-break: break-all;
    }
  }
  .profile-more-btn {
    padding: 1rem;
    font-size: 1.2rem;
    width: 100%;
    background-color: $color-aqua;
    color: $color-white;
    text-align: center;
  }
}


@media screen and (max-width: 800px) {
  .profile-mini-wrapper {
    width: 100%;
    padding: 0.5rem 1rem 0rem 1rem;
    .profile-img-wrapper {
      // width: 4rem;
      flex-shrink: 0;
      img {
        height: 3rem;
        width: 3rem;
      }
    }
    .nickname {
      width: 5rem;
      font-size: 0.9rem;
      // width: 6rem;
      flex-shrink: 0;
    }
    .userdesc {
      font-size: 0.8rem;
      flex-grow: 1;
    }
  }
}
