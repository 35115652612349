@import "./pages/signup";
@import "./pages/login";
@import "./pages/intro";
@import "./pages/board";
@import "./pages/photoboard";
@import "./pages/album";
@import "./pages/document";
@import "./pages/userinfo";
@import "./pages/photo";
@import "./pages/post";
@import "./pages/home";
@import "./pages/exhibition";
@import "./pages/mighty";
@import "./pages/management";

.section-wrapper {
  display: flex;
  flex-grow: 1;
  background-color: #f0f0f0;
}

section {
  position: relative;
  width: 900px;
  padding-top: 15px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 50px;
  margin: 0 auto;
  background-color: $color-white;
  font-size: 13px;
  // padding: 30px;
  h2 {
    font-family: "S-CoreDream-5Medium";
    font-size: 23px;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
    color: $color-blue1;
  }
}

@media screen and (max-width: 800px) {
  section {
    width: 100%;
    display: block;
    padding: 0rem 0rem 1rem 0rem;
  }
}
