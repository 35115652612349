/**********  ENIF  **********/
.enif-modal-wrapper {
    position: fixed;
    display: flex;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    .enif-btn-common {
        float: right;
        bottom: 20px;
        right: 20px;
    }
}

.enif-f-1p2x {
    font-size: 1.2rem;
}

.enif-f-2x {
    font-size: 2rem;
}

.enif-popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    animation-name: appear;
    animation-duration: 300ms;
}

.enif-modal-close {
    position: absolute;
    right: 10px;
    color: $color_gray2;
    &:hover {
        color: $color_gray5;
    }
}



.enif-popup-content {
    position: relative;
    width: 500px;
    padding: 2rem 2rem;
    background-color: $color-white;
    z-index: 99;
    animation-name: slide-in;
    animation-duration: 0.5s;
    h3 {
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .input-text {
        input {
            height: 2rem;
            width: 100%;
        }    
    }
    textarea {
        height: 8rem;
        width: 100%;
        resize: none;
    }

    .enif-table {
        width: 100%;
        caption {
            text-align: center;
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: 15px;
        }
        tr {
            margin: 10px 0px 10px 0px;
        }
        th {
            vertical-align: middle;
            font-size: 15px;
            font-weight: bold;
        }
        td {
            padding: 5px;
        }
    }
}
@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(-150px);
    }
    to {
        transform: translateY(0);
    }
}

.enif-popup .enif-btn-common {
    // position: relative;
    // position: absolute;
    float: right;
    bottom: 20px;
    right: 20px;
}

.enif-btn-common {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    font-size: 1rem;
    // font-weight: bold;
    border: none;
    background: none;
    cursor: pointer;
    // background: #b3cae5;
}

.enif-btn-cancel {
    color: #b4b4b4;
    font-size: 20px;
    &:hover {
        // font-weight: bold;
        color: rgb(105, 105, 105);
    }
}

.enif-btn-ok {
    color: #57adb6;
    font-size: 20px;
}

.enif-btn-circle {
    width: 50px;
    height: 50px;
    // font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color_aqua;
    box-shadow: 1px 1px 3px 0px #949494;
    border: none;
    border-radius: 50%;
    color: $color-white;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
        background-color: darken($color_aqua, 10%);
    }
}

.enif-pos-right {
    right: 20px;
}

.enif-pos-bottom-right {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.enif-pos-sticky {
    position: sticky;
    margin-left: 105%;
    bottom: 20px;
}


.enif-btn-common-rec {
    height: 3em;
    width: 20em;
    margin: 1em auto;
    letter-spacing: 0.2rem;
    color: white;
    background-color: #49a0ae;
    border: none;
    // font-weight: bold;
    &:disabled {
        background-color: #666666;
        cursor: not-allowed;
    }
}

.enif-input {
    height: 20px;
    width: 100%;
    display: block;
}

.enif-input-field {
    margin: 0 auto;
    margin-top: 15px;
    width: 300px;
    font-size: 0.875rem;
    label {
        font-size: 0.9rem;
        font-weight: bold;
        margin: 3px;
    }
    input {
        // margin-top: 5px;
        height: 35px;
        width: 100%;
        display: block;
    }
    p {
        color: red;
    }
    textarea {
        margin-top: 5px;
        display: block;
        width: 100%;
        height: 100px;
        resize: none;
        overflow: auto;
    }
}

.enif-divider {
    margin: 10px 0px;
    height: 2px;
    background-color: #E8E8E8;
}

// .enif-input-field input:invalid {
//     // background-color: #fff4f4;
//     border: 1px solid #ff7e7e;
// }

@media screen and (max-width: 800px) {

    .enif-popup-content {
        width: 90%;
        padding: 1rem 1rem 3rem 1rem;
        margin-top: 10%;
    }

    .enif-btn-circle {
        // position: fixed;
        width: 3.5rem;
        height: 3.5rem;
        z-index: 5;
    }

    .enif-fixed-btm {
        width: 100%;
        position: fixed;
        bottom: 0;
    }

    .enif-pos-sticky {
        position: sticky;
        display: inline-block;
        margin-left: calc(95% - 3.5rem);
        bottom: 2rem;
    }

    .enif-hide-desktop {
        display: initial;
    }

    .enif-hide-mobile {
        display: none;
    }

    .enif-overflow-hidden-mobile {
        overflow: hidden;
    }    
}

/**********  ENIF  **********/