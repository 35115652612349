.board-wrapper {
  // padding-top: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .enif-btn-circle {
    margin-top: auto;
  }
  .board-desc {
    padding: 0px 10px;
    font-size: 14px;
    color: $color-gray2;
    text-align: center;
  }
}

.board-search-wrapper {
  margin: 1rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .board-select-wrapper {
    display: flex;
    padding: 0px 10px;
  }
  .board-search-input {
    // border: 1px solid $color-gray4;
    display: flex;
    align-items: center;
    margin-right: auto;
    input {
      border: 1px solid $color-gray5;
      height: 100%;
      padding: 0px 5px;
    }
  }
  .board-search-btn {
    background-color: $color-gray2;
    color: $color-white;
    border: none;
    padding: 5px;
  }
  .board-btn-write-wrapper {
    flex-shrink: 0;
    // margin-top: 1rem;
    margin-left: auto;
  }
}

.board-btn-write {
  margin-left: auto;
  background-color: $color-aqua;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  color: $color-white;
}

.background-star {
  color: #ffd45f;
  font-size: 17px;
  letter-spacing: 15px;
  overflow: hidden;
  flex-shrink: 1;
  &.solo {
    flex-shrink: 0;
    letter-spacing: 10px;
    padding-left: 5px;
  }
}

.postboard-title-wrapper {
  display: flex;
  padding: 20px 0px;
  a {
    flex-shrink: 0;
  }
  h2 {
    flex-shrink: 0;
    padding: 0px 17px 0px 10px;
  }
}

.post-list-wrapper {
  margin: 20px 0px;
}

.post-list-header {
  display: flex;
  background-color: $color-gray2;
  color: $color-white;
  font-size: 15px;
  .post-list-header-unit {
    padding: 13px 0px;
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .post-list-header-1 {
    // flex-basis: 50%;
    flex-grow: 1;
  }
  .post-list-header-2 {
    flex-basis: 85px;
  }
  .post-list-header-3 {
    flex-basis: 45px;
  }
  .post-list-header-4 {
    flex-basis: 90px;
  }
}

.post-list {
  display: flex;
  padding: 13px 0px;
  border-top: 1px solid $color-gray4;
  font-size: 14px;
  &:last-child {
    border-bottom: 1px solid $color-gray4;
  }
  &:hover {
    background: #e7f2ff;
  }
  a {
    color: #000000;
  }
  .post-list-unit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .post-list-unit-left {
    display: flex;
    // width: 80%;
    flex-grow: 1;
    flex-shrink: 1;

    .post-list-unit-left-bot {
      display: flex;
      width: 170px;
      flex-shrink: 0;
    }
  }
  .post-list-unit-title {
    // width: 62.5%;
    // flex-basis: 50%;
    padding-left: 20px;
    word-break: break-all;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    h5 {
      width: 100%;
    }
  }
  .post-list-unit-2 {
    flex-basis: 85px;
    flex-grow: 0;
    flex-shrink: 0;
    color: #7f7f7f;
  }
  .post-list-unit-3 {
    flex-basis: 45px;
    flex-grow: 0;
    flex-shrink: 0;
    // padding: 0rem 0.2rem;
    .icon-wrapper {
      font-size: 1rem;
      display: flex;
      align-items: center;
    }
  }
  .post-list-unit-4 {
    flex-basis: 90px;
    text-align: center;
    a {
      color: #7f7f7f;
    }
  }

  .post-list-unit-right {
    display: inline-block;
    width: 20%;
  }

  .post-title {
    margin-bottom: 10px;
    font-size: 16px;
    cursor: pointer;
    h5 {
      word-break: break-all;
    }
  }

  .post-author {
    display: inline-block;
    cursor: pointer;
    color: $color-gray2;
  }

  .post-created {
    display: inline-block;
    margin-left: 10px;
  }

  .post-comment-num {
    font-size: 18px;
  }
}

.writepost-wrapper {
  // margin: 0px;
  .writepost-header {
    display: flex;
    // justify-content: center;
    padding: 15px;
    font-size: 17px;
    color: $color-white;
    background-color: $color_gray2;
    h5 {
      flex-grow: 1;
      text-align: center;
    }
  }

  .writepost-title {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    input {
      height: 40px;
      width: 100%;
      border: none;
      padding-left: 10px;
      font-size: 17px;
    }
  }

  .writepost-content {
    display: block;
    // .writepost-quill {
    //   .ql-container {
    //     width: 100%;
    //     height: 400px;
    //     font-family: "D2Coding";
    //     font-size: 14px;
    //   }
    // }
    textarea {
      width: 100%;
      height: 400px;
      resize: none;
    }
  }
  .editor-wrapper {
    margin-top: 10px;
    display: block;
  }
  .editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    user-select: none;
  }
  .editor-textarea {
    border: 1px solid #f1f1f1;
    padding: 5px;
    border-radius: 2px;
    height: 400px;
    // cursor: text;
  }
  .btn-wrapper {
    .enif-btn-cancel {
      float: left;
    }
    .enif-btn-ok {
      float: right;
    }
  }
}

.sa-ck {
  font-size: 0.9rem;

  .ck.ck-voice-label {
    display: none;
}
  .ck.ck-content {
    min-height: 300px;
    line-height: 150%;
    padding: 0px 1rem;
    ol {
      list-style: decimal;
    }
    ul {
      list-style: disc;
      padding: 0.5rem 2rem;
    }
    h2 {
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 150%;
    }
    h3 {
      font-weight: bold;
      font-size: 1rem;
      line-height: 150%;
    }
    h4 {
      font-weight: bold;
      font-size: 0.9rem;
      line-height: 150%;
    }
    p {
      line-height: 150%;
    }
    a {
      color: #00f;
      text-decoration: underline;
    }
  }
}

.sa-viewer {
  .ck {
    &.ck-content {
      border: #C4C4C4;
    }
    &.ck-toolbar {
      border: none;
    }
  }
}

.sa-editor {
  border: 1px solid #C4C4C4;
  // .ck {
  //   &.ck-content {
  //     border: #C4C4C4;
  //   }
  //   &.ck-toolbar {
  //     border: none;
  //   }
  // }
}

.docboard-top-menu-wrapper {
  display: flex;
  padding-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .board-wrapper {
    // padding-top: 1.5rem;
    .enif-btn-circle {
      margin-top: -1.5rem;
    }
  }

  .writepost-wrapper {
    .editor-toolbar {
      display: none;
    }
    .editor-textarea {
      display: none;
    }
  }

  .post-list-header {
    display: none;
  }

  .post-list {
    padding: 0.8rem 0.5rem;
    // padding: 0px 10px;
    .post-list-unit-left {
      flex-direction: column;
      .post-list-unit-left-bot {
        width: 100%;
        margin-top: 0.5rem;
        padding-left: 1rem;
      }
    }
    .post-list-unit-title {
      width: 100%;
      padding-left: 1rem;
    }
    .post-list-unit-2 {
      color: $color-gray1;
      font-size: 0.8rem;
      flex-basis: 4rem;
      justify-content: flex-start;
    }
    .post-list-unit-4 {
      flex-basis: 4rem;
      font-size: 0.8rem;
      color: #7f7f7f;
    }
  }
}
