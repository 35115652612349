
// 각 sass 파일로 분리 예정
/****** for mobile ~ 800px ******/
@media screen and (max-width: 800px) {

    aside {
        display: none;
    }

    .enif-input-field {
        width: 100%;
    }
}

