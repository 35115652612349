/*
moonphase scss copyright
https://github.com/jshakes/moontonight
https://github.com/jshakes/moontonight/blob/master/_assets/scss/modules/_moon.scss
*/


$moon-size: 100%;

@mixin moon($percent) {
  font-size: 0;
  display: inline-block;
  width: $moon-size;
  height: $moon-size;

  &.northern-hemisphere {
    transform: rotate(180deg);
  }
  .half {
    display: inline-block;
    width: $moon-size / 2;
    height: $moon-size;
    overflow: hidden;
    position: relative;
    .ellipse {
      position: absolute;
      width: 200%;
      height: 100%;
      background: #ffda0a;
      border-radius: 100%;
      transition: all, 0.25s;
      &.black {
        background: $color-white;
        z-index: 2;
      }
      &.white {
        z-index: 1;
        border: 1px solid #ffda0a;
      }
    }
    &:first-child .ellipse {
      &.black {
        @if($percent > 25 and $percent < 75) {
          width: 0;
          left: 100%;
        }
        @if($percent <= 25) {
          left: #{100 - ((50 -$percent * 2) * 2%)};
          width: #{(50 -$percent * 2) * 4%};
        }
      }
      &.white {
        left: 100%;
        width: 0;
        @if($percent <= 50) {
          left: 0;
          width: 200%;
        }
        @if($percent > 50 and $percent < 75) {
          left: #{($percent - 50) * 4%};
          width: #{(75 - $percent) * 8%};
        }
      }
    }
    &:last-child .ellipse {
      left: -50%;
      width: 0;
      @if($percent <= 25) {
        visibility: hidden;
      }
      &.white {
        @if($percent > 25 and $percent < 50) {
          left: #{($percent - 25) * -4%};
          width: #{($percent - 25) * 8%};
          z-index: 3;
        }
        @if($percent >= 50) {
          width: 200%;
          left: -100%;
        }
      }
      &.black {
        @if($percent > 75) {
          left: #{(75 - $percent) * 4%};
          width: #{($percent - 75) * 8%};
        }
      }
    }
  }
}

@for $i from 0 through 100 {
  .phase-#{$i} {
    @include moon($i);
  }
}