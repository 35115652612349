.home-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .home-row {
    display: flex;
    margin-top: 7px;
  }

  .home-row-mobile {
    display: none;
  }
}
.side-left {
  position: absolute;
  margin-top: 30px;
  top: 0px;
  left: -190px;
  height: 100%;
}

.side-right {
  position: absolute;
  margin-top: 30px;
  top: 0px;
  right: -100px;
  height: 100%;
  // position: sticky;
  // margin-left: 870px;
  // margin-top: 30px;
  // top: 70px;
  // height: 0;
}

.rise-set-wrapper {
  position: sticky;
  top: 70px;
  background: $color_blue3;
  width: 170px;
  padding: 30px 0px 25px 0px;
  border-radius: 20px;
  color: $color-white;
  text-align: center;
  .moon-phase-wrapper {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin: auto;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    .moon-phase {
      height: 64px;
      width: 64px;
      border-radius: 50%;
      box-shadow: inset -8px 0px 3px 0px $color-white;
      background-color: #ffda0a;
    }
    .moon-container {
      height: 64px;
      width: 64px;
      position: relative;
    }
  }
  h5 {
    margin: 20px 0px 5px 0px;
    font-size: 15px;
  }
  p {
    margin: 10px 0px;
  }
}

.side-bar-wrapper {
  // position: relative;
  position: sticky;
  top: 70px;
  width: 80px;
  height: 285px;
  border-radius: 20px;
  padding: 20px 0px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
  .btn-top-up-link {
    margin-top: auto;
  }
}
.btn-top-up {
  margin-top: auto;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid $color-aqua;
  border-radius: 50%;
  color: $color-gray2;
  font-size: 15px;
  font-weight: bold;
  transition: all 0.2s ease-out;

  &:hover {
    color: $color-aqua;
  }
}

.btn-top-up-mobile {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transition: all 0.2s ease-out;

  .btn-top-up {
    width: 3rem;
    height: 3rem;
    background-color: rgba(231, 231, 231, 0.9);
    border: none;
    font-size: 0.7rem;
    box-shadow: 1px 1px 3px 0px #949494;
  }
}

.soundbox-wrapper {
  margin-top: 20px;
  margin-bottom: 3px;

  .soundbox-title {
    // width: 160px;
    position: relative;
    background-color: $color-aqua;
    color: white;
    font-size: 25px;
    text-align: center;
    margin: 0 auto;
    padding: 10px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .soundbox-contents-wrapper {
    position: relative;
    background-color: $color_gray5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    justify-content: center;
    ::-webkit-scrollbar {
      display: none;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 90%;
      height: 25px;
      margin: 0 auto;
      background: linear-gradient(rgba(232, 232, 232, 0.01), $color_gray5);
      // background: red;
    }
    .soundbox-contents {
      width: 95%;
      height: 200px;
      position: relative;
      overflow: auto;
      padding: 20px 30px;
      color: #08253e;
      font-weight: 60;
      .ck-content {
        background-color: $color-gray5;
      }
      a {
        color: #08253e;
      }
      h5 {
        font-weight: bold;
        font-size: 20px;
        margin: 2px 0px 15px 0px;
      }
      p {
        line-height: 17px;
      }
    }
  }
}

.new-comment-list {
  padding: 11px 12px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  position: relative;
  &:last-child {
    border-bottom: 1px solid #e8e8e8;
  }
  a {
    color: #000000;
  }
  .new-comment-boardname {
    width: 25%;
    flex-shrink: 0;
    a {
      color: $color-gray2;
    }
  }
  .new-comment-contents {
    flex-grow: 1;
    width: 55%;
  }
  .new-comment-contents-top {
    // flex-grow: 1;
    .new-comment-contents-title {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $color-gray2;
    }
  }
  .new-comment-contents-bot {
    display: flex;
    width: 100%;
    // height: 15px;
    margin-top: 5px;
    line-height: 14px;
    overflow: hidden;
  }
  .new-comment-comment {
    flex-grow: 1;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
  }
  .new-comment-date {
    flex-shrink: 0;
    // width: 20%;
    color: $color-gray2;
  }
}



.new-exhibitions-wrapper {
  width: 100%;
  padding: 5px;
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: $color_blue3;
    padding: 10px 5px;
  }
  .new-exhibition-flex {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
  }
  .new-exhibition-list-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: flex-start;
    overflow: hidden;
  }
  .new-exhibition-list {
    height: 170px;
    width: 130px;
    position: relative;
    // margin: 0px 10px;
    flex-shrink: 0;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .new-exhibitions-arrow {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.left {
      left: -25px;
    }
    &.right {
      right: -25px;
    }
  }
}

@media screen and (max-width: 800px) {
  .home-wrapper {
    .home-row {
      flex-direction: column;
      margin-top: 0;
    }
    .home-row-mobile {
      display: flex;
      margin: 0.5rem 0;
      padding: 0 0.7rem;
    }
    .home-side-left {
      display: none;
      position: initial;
      margin-left: 10px;
      margin-top: 10px;
    }
  }

  .side-right {
    display: none;
  }

  .rise-set-mobile-wrapper {
    background: $color_blue3;
    // width: 50%;
    flex-basis: 50%;
    flex-grow: 1;
    padding: 0 0.3rem;
    margin-right: 1.5%;
    border-radius: 1rem;
    color: $color-white;
    display: flex;
    align-items: center;
    // height: 3.5rem;
    // text-align: center;
    .moon-phase-wrapper {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      // margin: auto;
      background-color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      .moon-phase {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        box-shadow: inset -8px 0px 3px 0px $color-white;
        background-color: #ffda0a;
      }
      .moon-container {
        height: 2.7rem;
        width: 2.7rem;
        position: relative;
      }
    }
    .rise-set-desc {
      padding: 0 0.5rem;
      h5 {
        font-weight: bold;
        margin: 0.3rem 0;
      }
    }
  }

  .ext-link-mobile {
    width: 50%;
    // margin: 0 0.2rem;
    display: flex;
    flex-basis: 50%;
    flex-shrink: 1;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .link-img {
      width: 32%;
      &::before {
        content: "";
        padding-top: 100%;
        float: left;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 1rem;
        vertical-align: bottom;
      }
    }
  }

  .soundbox-wrapper {
    width: 100%;
    padding: 0rem 0.5rem;
    margin-top: 1rem;

    .soundbox-contents-wrapper {
      .soundbox-contents {
        height: 10rem;
        padding: 1rem 1rem;
        h5 {
          margin: 0.1rem 0rem;
        }
        p {
          font-size: 0.9rem;
          line-height: 1.1rem;
        }
      }
    }
  }



  .new-exhibitions-wrapper {
    // overflow: auto;
    .new-exhibition-list-wrapper {
      overflow: auto;
      &::after {
        position: absolute;
        right: 0;
        content: "";
        width: 2rem;
        height: 100%;
        // background-color: $color-white;
        background: linear-gradient(90deg, rgba(232, 232, 232, 0.01), rgba(232, 232, 232, 0.7));
      }
    }
    .new-exhibition-list {
      margin-right: 1rem;
    }
    .new-exhibitions-arrow {
      display: none;
    }
    // .new-exhibitions-arrow {
    //     position: absolute;
    //     height: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     &.left {
    //         left: -25px;
    //     }
    //     &.right {
    //         right: -25px;
    //     }
    // }
  }
}
