.color-pink {
  color: $color-pink;
}

.color-gray1 {
  color: $color-gray2;
}

.break-line {
  word-break: break-word;
}

.actions-drawer-target {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.actions-drawer {
  // position: relative;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 1;
  .actions-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    height: auto;
    // width: 90px;
    // user-select: none;
    margin: 0px 0px 0px 0px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s;
    font-size: 17px;
    .action-unit-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-gray5;
      color: #646464;
      transition: background-color 0.2s;
      cursor: pointer;
      &:hover {
        background-color: $color-gray1;
        color: $color-white;
      }
      &:last-child {
        .action-unit {
          border-bottom: none;
        }
      }
      .action-unit {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 4px;
        margin: 0px 8px;
        width: 100%;
        border-bottom: 1px solid $color-gray4;
        white-space: nowrap;
      }
    }
    &.opened {
      max-height: 300px;
    }
  }
}

.mini-user-list {
  position: absolute;
  top: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border: 1px solid $color-black;
  z-index: 1;
  .mini-user-unit {
    display: flex;
    align-items: center;
    padding: 5px;
    &:hover {
      background: $color-gray5;
    }
  }
  .mini-user-profile {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.mini-user-selected {
  display: flex;
  align-items: center;
  background-color: $color-gray3;
  border: 1px solid $color-gray1;
  border-radius: 5px;
  padding: 2px;
  // background-color: $color-white;
}

.actions-wrapper {
  // display: flex;
  // justify-content: space-between;
  // padding-top: 5px;

  position: relative;
  // height: 30px;
  user-select: none;
  margin: 0px 23px;

  // .edit-delete-wrapper {
  //     position: absolute;
  //     left: 0px;
  //     .edit-wrapper {
  //         display: inline-block;
  //         margin-right: 8px;
  //     }
  //     .delete-wrapper {
  //         display: inline-block;
  //     }

  // }
}

.user-actions-wrapper {
  position: absolute;
  top: 50%;
  // right: 0;
  height: auto;
  width: 8rem;
  // user-select: none;
  margin: 0px 0px 0px 0px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s;
  font-size: 17px;
  box-shadow: 1px 1px 1px 2px $color-gray4;
  .action-unit-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray5;
    color: #646464;
    transition: background-color 0.2s;
    cursor: pointer;
    a {
      color: #646464;
    }
    &:hover {
      background-color: $color-gray1;
      color: $color-white;
      a {
        color: $color-white;
      }
    }
    &:last-child {
      .action-unit {
        border-bottom: none;
      }
    }
    .action-unit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 4px;
      border-bottom: 1px solid $color-gray4;
    }
  }
  &.opened {
    max-height: 300px;
  }
}

.nums-wrapper {
  font-size: 20px;
  // position: absolute;
  // right: 0px;
  display: flex;
  // padding-bottom: 10px;
  .view-num-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    color: $color-gray2;
  }
  .like-num-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    // color: #a3a3a3;
    color: #f0645e;
  }
  .comment-num-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-gray2;
  }
}

.tag-wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}
.tag-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  .tag-unit {
    margin: 15px 5px 10px 5px;
    label {
      width: 100%;
      padding: 8px;
      border-radius: 12px;
      font-size: 14px;
      user-select: none;
      cursor: pointer;
    }
    input {
      display: none;
    }
    .tag-label-1 {
      border: 1px solid #e25d7f;
    }
    input:checked + .tag-label-1 {
      color: #eeeeee;
      background-color: #e25d7f;
    }
    .tag-label-2 {
      border: 1px solid #3a68a0;
    }
    input:checked + .tag-label-2 {
      color: #eeeeee;
      background-color: #3a68a0;
    }
  }
  .tag-all {
    label {
      border: 1px solid #686868;
    }
    input:checked + label {
      color: #eeeeee;
      background-color: #686868;
    }
  }
}

.paginator-wrapper {
  display: flex;
  margin: auto 10px;
  padding: 20px 0px;
  // margin-top: auto;
  justify-content: center;
  i {
    color: $color-gray4;
    &:hover {
      color: $color-gray2;
    }
  }
  ul {
    display: flex;
    color: $color-gray1;
  }
  li {
    padding: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .current {
    font-weight: bold;
  }
}

.file-icon {
  margin: 3px 3px;
  .file-name {
    display: none;
  }
  &:hover {
    .file-name {
      font-size: 13px;
      color: $color-gray1;
      background-color: $color-gray5;
      border: 1px solid black;
      padding: 5px;
      position: absolute;
      display: block;
    }
  }
}

.color-img {
  color: #010e2c;
}
.color-doc {
  color: #6677c5;
}
.color-xls {
  color: #00791a;
}
.color-pdf {
  color: #ff2d1e;
}
.color-zip {
  color: #fbc327;
}
.color-hwp {
  color: #3ea3de;
}

.progress-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 50;
  justify-content: center;
  align-items: center;
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.aaa-img-loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
}

.aaa-img-loading {
  opacity: 0;
  background-color: $color-gray3;
  width: 100%;
  height: auto;
}

.loader {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loader circle {
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

#svgPathStar {
  transition: all 0.2s;
}
@media screen and (max-width: 800px) {
  .actions-wrapper {
    margin: 0rem 1.5rem;
  }
}
