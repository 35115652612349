.intro-wrapper {
    font-size: 13px; 
    margin: 0 auto;
    padding-left: 10%;
    padding-right: 10%;
    }
    
.intro-div-wrapper {
    padding: 15px 23px 10px 23px;
    line-height: 1.3em;
    h3 {
        color: #75a3e6;
        font-size: 22px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: bold;
    }
    h4 {
        // padding: 10px;
        font-size: 16px;
        font-weight: bold;
    }
    .intro-content {
        font-size: 16px;
        line-height: 1.5;
        display: block;
        margin-bottom: 20px;
        .equipment-wrp {
            margin: 10px 0px;
        }
        .img-left {
            margin: 10px;
            float: left;
            width: 200px;
            display: block;
        }
        .img-right {
            margin: 10px;
            float: right;
            width: 200px;
            display: block;
            p {
                text-align: right;
                color: #646464;
                font-size: 14px;
            }
        }
        .img-sanghui {
            width: 150px;
        }
        .img-sanghui {
            width: 150px;
        }
        .img-gwak {
            width: 140px;
        }
    }
    ul {
        list-style: inside;
        color: rgb(85, 150, 255);   
        li {
            a {
                font-size: 0.9rem;
                line-height: 1.3em;
                cursor: pointer;
                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
    h5 {
        line-height: 2em;
        font-weight: bold;
    }
}

.intro-contact-wrapper {
    h4 {
        font-size: 19px;
        margin: 10px 0px;
        line-height: 25px;
    }
    .kakao-map {
        width: 100%;
        height: 400px;
    }
    .link-navi {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        color: $color-gray2;
        justify-content: flex-end;
        text-align: right;
    }
    .intro-content {
        margin-top: 15px;
        color: $color-gray1;
    }
    .intro-contact-btn-wrapper {
        button {
            width: 100%;
            border: none;
            background-color: $color-blue3;
            color: $color-white;
            padding: 15px;
            font-size: 20px;
        }
    }
}

.officers-wrapper {
    .gen-wrapper {
        display: flex;
        min-height: 70px;
        margin-top: 10px;
        .generation {
            flex-basis: 12%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fad55f;
            text-align: center;
            color: #64635f;
            border-radius: 20px;
            font-weight: bold;
        }
        .member {
            display: flex;
            margin-left: 10px;
            flex-basis: 88%;
            background: #f2f2f2;
            border-radius: 20px;
            .president {
                flex-basis: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 3px solid $color-white;
            }
            .officers {
                flex-basis: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                font-size: 13px;
            }
        }
    }
}

.intro-history-wrapper {
    // height: 1000px;
    position: relative;
    padding-top: 20px;
    .intro-line {
        position: absolute;
        left: 50%;
        width: 2px;
        height: calc(100% - 75px);
        background-color: #b4b4b4;
        z-index: 0;
    }
    .intro-history-unit {
        margin-top: -40px;
        h4 {
            padding: 5px 0px;
        }
        p {
            word-spacing: -0.1rem;
        }

        &:nth-child(odd) {
            text-align: left;
            .unit-half {
                padding-left: calc(50% + 30px);
            }
            .mark-year {
                right: 39px;
                &::after {
                    position: absolute;
                    top: 3px;
                    left: 24px;
                    height: 5px;
                    width: 5px;
                    border: 2px solid $color_blue5;
                    border-radius: 50%;
                    background-color: $color_blue5;
                    content: "";
                }
            }
        }
        &:nth-child(even) {
            text-align: right;
            .unit-half {
                padding-right: calc(50% + 30px);
            }
            .mark-year {
                left: 41px;
                &::before {
                    position: absolute;
                    top: 3px;
                    right: 24px;
                    height: 5px;
                    width: 5px;
                    border: 2px solid $color_blue5;
                    border-radius: 50%;
                    background-color: $color_blue5;
                    content: "";
                }
            }
        }
        &:nth-child(2) {
            .mark-year {
                top: 34px;
                left: 49px;
                height: 36px;
                width: 36px;
                border: 7px solid $color-blue3;
                background-color: $color-white;
                &::before {
                    top: 5px;
                    right: 32px;
                }
            }
        }
        &:nth-last-child(1) {
            .mark-year {
                top: 34px;
                left: 49px;
                height: 36px;
                width: 36px;
                border: 7px solid $color-blue3;
                background-color: $color-white;
                &::before {
                    top: 5px;
                    right: 32px;
                }
            }
        }

        .mark-year {
            display: inline-block;
            position: relative;
            top: 28px;
            height: 20px;
            width: 20px;
            border: 2px solid $color-blue3;
            border-radius: 50%;
            background-color: $color-blue3;
        }
    }
}

.intro-content.regulation {
    font-family: 'Nanum Myeongjo', serif;
    h2 {
        margin: 0;
        padding: 0;
        font-family: 'Nanum Myeongjo', serif;
        font-size: 1.7rem;
    }
    h3 {
        margin: 0;
        padding: 0;
        font-family: 'Nanum Myeongjo', serif;
        font-size: 1.3rem;
        color: $color-black;
    }
    p {
        font-family: 'Nanum Myeongjo', serif;
        font-size: 1rem;
        margin: 0 0.3rem;
    }
    .chapter-wrapper {
        margin: 1.5rem 0;
    }
    .article-wrapper {
        margin: 1rem 0rem 1rem 1rem;
    }
    .clause-wrapper {
        margin: 0rem 0rem 0rem 0.5rem;
        display: flex;
    }
}

.arrow-wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #49A0AE;
    border: none;
    border-radius: 50%;
    box-shadow: 1px 1px 3px 0px #949494;
    color: $color-white;
}

@media screen and (max-width: 800px) {

    .intro-div-wrapper {
        padding: 1.3rem 1.5rem 1rem 1.5rem;
        .intro-content {
            .img-logo {
                width: 8rem;
                margin: 0.5rem;
            }
        }
    }

    .intro-contact-wrapper {
        .kakao-map {
            width: 100%;
            height: 15rem;
        }
    }

    .officers-wrapper {

        .gen-wrapper {
            min-height: 4rem;
            .generation {
                border-radius: 1rem;
                flex-basis: 20%;
            }
            .member {
                display: flex;
                margin-left: 0.5rem;
                flex-basis: 80%;
                background: #f2f2f2;
                border-radius: 1rem;
                .president {
                    flex-basis: 25%;
                    font-size: 0.9rem;
                }
                .officers {
                    flex-basis: 75%;
                    padding-right: 0.5rem;
                    font-size: 0.7rem;
                }
            }
        }
    }
}