$color_black: #000000;

$color_gray1: #646464;
$color_gray2: #A3A3A3;
$color_gray3: #C9C9C9;
$color_gray4: #DDDDDD;
$color_gray5: #F1F1F1;
$color_gray6: #F4F4F4;

$color_white: #FFFFFF;


$color_blue1: #000E2C;
$color_blue2: #466890;
$color_blue3: #7193C4;
$color_blue4: #74B9FF;
$color_blue5: #CDD9EA;

$color_aqua: #49A0AE;

$color_red1: #E25D80;
$color_red2: #FF5273;

$color_pink: #F0645E;

$color_yellow1: #FFD45F;
$color_yellow2: #FFD373;
$color_yellow3: #FDEED5;
$color_yellow4: #FFE6A4;
