/****** FOOTER ******/

footer {
    background-color: #434343;
    color: white;
    padding: 20px;
}

.footer-contents {
    width: 100%;
    text-align: center;
    p {
        margin: 10px;
        line-height: 1.5;
    }
}

/****** FOOTER ******/

@media screen and (max-width: 800px) {

    .footer-contents {
        p {
            font-size: 0.8rem;
        }
    }
}